<template>
    <div class="registerSuccess">
        <img src="@/assets/icon/registerSuccess.png" alt="注册成功" />
        <span v-if="loginStatus.register">账户：{{ loginStatus.message }} 注册成功</span>
        <span v-else>账户：{{ loginStatus.message }} 修改密码成功</span>
        <el-button type="primary" @click="toLogin">立即登录</el-button>
    </div>
</template>

<script>
export default {
    name: "RegisterSuccess",
    props:['loginStatus'],
    data() {
        return {
            title: "账户注册成功"
        };
    },
    methods: {
        toLogin() {
            this.$emit("changedLoginComponent", {
                status:"index",
                register:false,
                message:""
            });
        }
    }
};
</script>

<style scoped lang="scss">
.registerSuccess {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        width: 64px;
        height: 64px;
        margin-bottom: 12px;
    }
    span {
        margin-bottom: 32px;
        color: #000;
        font-weight: 500;
        font-size: 20px;
    }
    .el-button {
        width: 300px;
        height: 40px;
    }
}
</style>
