<template>
  <div>
    <el-alert class="error-alert" v-show="errorVisible" :title="errorMessage" type="error" :closable="true" show-icon> </el-alert>
    <div class="title">忘记密码</div>
    <el-form :model="form" status-icon :rules="rules" ref="rules">
      <div style="position: absolute">
        <input type="text" style="height: 0; width: 0; border: 0; padding: 0" />
        <input type="password" style="height: 0; width: 0; border: 0; padding: 0" />
      </div>
      <el-form-item prop="phone">
        <el-input class="login-input" type="tel" v-model.trim="form.phone" placeholder="绑定手机号"> </el-input>
      </el-form-item>
      <el-form-item prop="code">
        <el-input class="pin-input" type="text" v-model.trim="form.code" maxlength="6" placeholder="输入验证码"> </el-input>
        <el-button class="pin-button" type="default" :loading="loading" @click="handleSendSms(true)">
          {{ codeButton }}
        </el-button>
      </el-form-item>
      <el-form-item prop="password">
        <el-input class="login-input" type="password" v-model.trim="form.password" maxlength="15" @blur="handleEmitCheckRepassword" show-password placeholder="请输入新密码"> </el-input>
      </el-form-item>
      <el-form-item prop="rePassword">
        <el-input class="login-input" type="password" v-model.trim="form.rePassword" show-password maxlength="15" placeholder="确认密码"> </el-input>
      </el-form-item>
      <div class="login-flex">
        <el-button class="register-button" type="primary" @click="submitForm"> 修改密码 </el-button>
        <span @click="changedComponent">{{ buttonTitle }}</span>
      </div>
    </el-form>
  </div>
</template>

<script>
import RegexLib from '@/assets/js/regex-lib';
import { sendSms, restPassword, checkPhoneHaven } from '@/service/login.js';

export default {
  name: 'ForgetPassword',
  data() {
    return {
      errorVisible: false,
      loading: false,
      interval: null,
      timer: 0,
      errorMessage: '验证码不正确',
      buttonTitle: '想起密码，去登录',
      codeButton: '获取验证码',
      form: {
        phone: '',
        code: '',
        password: '',
        rePassword: '',
        bizType: 'forgetPassword'
      },
      rules: {
        phone: [
          { required: true, message: '请输入手机号!', trigger: 'blur' },
          { pattern: RegexLib.Phone, message: '请输入正确的手机号!', trigger: 'blur' },
          { validator: this.checkPhone, trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码!', trigger: 'blur' },
          { min: 1, max: 6, message: '长度在 1 到 6 个字符', trigger: 'blur' },
          { pattern: RegexLib.SMSCheckCode, message: '验证码只能输入数字', trigger: 'blur' }
        ],
        password: [
          { required: true, min: 10, max: 20, message: '请输入10-20位密码（数字、大小写字母、特殊字符组合）!', trigger: 'blur' },
          { pattern: "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&+=,./?;'])(?=\\S+$).{10,20}$", message: '至少要包含数字、大小写字母、特殊字符（!@#$%^&+=）', trigger: 'blur' }
        ],

        rePassword: [
          { required: true, min: 10, max: 20, message: '请输入10-20位密码（数字、大小写字母、特殊字符组合）!', trigger: 'blur' },
          { pattern: "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&+=,./?;'])(?=\\S+$).{10,20}$", message: '至少要包含数字、大小写字母、特殊字符（!@#$%^&+=）', trigger: 'blur' },
          { validator: this.checkRePassword, trigger: 'blur' }
        ]
      }
    };
  },
  created() {
    if (localStorage.getItem('timer')) {
      this.timer = localStorage.getItem('timer');
      this.handleSendSms(false);
    }
    localStorage.clear();
  },
  methods: {
    /* 触发校验，提交表单 */
    submitForm() {
      this.$refs.rules.validate((valid) => {
        if (!valid) return;
        this.handleRestPassword();
      });
    },
    /* 重置密码 */
    async handleRestPassword() {
      const { success, data, message } = await restPassword(this.form);
      if (!success) {
        this.toast(message || '重置密码失败', 'error');
        return;
      }
      this.$emit('changedLoginComponent', {
        status: 'register-success',
        register: false,
        message: data
      });
    },
    /* 切换组件到登录 */
    changedComponent() {
      this.$emit('changedLoginComponent', {
        status: 'index',
        register: false,
        message: ''
      });
    },
    /* 计时器 */
    timerDown() {
      if (this.timer < 1) {
        clearInterval(this.interval);
        this.loading = false;
        this.codeButton = '获取验证码';
        localStorage.removeItem('forgotTimer');
      } else {
        this.codeButton = this.timer - 1 + '秒';
        localStorage.setItem('forgotTimer', this.timer);
        this.timer--;
      }
    },
    /* 发送手机验证码 */
    handleSendSms(param) {
      // 是否点击
      if (param) {
        this.$refs.rules.validateField('phone', (valid) => {
          // 校验手机
          if (!valid) {
            this.timer = 60;
            localStorage.setItem('forgotTimer', 60);
            sendSms({
              phone: this.form.phone,
              bizType: this.form.bizType,
              expired: 6000
            }).then((res) => console.log(res));
            this.loading = true;
            this.codeButton = this.timer + '秒';
            this.interval = setInterval(() => {
              this.timerDown();
            }, 1000);
          } else {
            return false;
          }
        });
      } else {
        this.loading = true;
        this.codeButton = this.timer + '秒';
        this.interval = setInterval(() => {
          this.timerDown();
        }, 1000);
      }
    },
    /* 校验手机号 */
    async checkPhone(rule, value, callback) {
      const { data } = await checkPhoneHaven({ phone: value });
      if (!data) {
        callback(new Error('当前手机号不存在'));
      } else {
        callback();
      }
    },
    /* 校验确认密码 */
    checkRePassword(rule, value, callback) {
      if (this.form.password != this.form.rePassword) {
        callback(new Error('两次输入密码不一致！'));
      } else {
        callback();
      }
    },
    /* 触发确认密码校验 */
    handleEmitCheckRepassword() {
      if (this.form.rePassword) {
        this.$refs.rules.validateField('rePassword');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/login.scss';

.login-input {
  width: 380px;
}

::v-deep .el-form-item__label,
::v-deep .el-form-item__content {
  height: inherit;
  line-height: inherit;
}

::v-deep .el-input,
::v-deep .el-input__inner {
  height: 40px;
}

.el-button {
  padding: 0 16px;
}
</style>
