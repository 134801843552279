<template>
  <div>
    <el-alert class="error-alert" v-show="errorVisible" :title="errorMessage" type="error" :closable="true" show-icon> </el-alert>
    <div class="title">修改密码</div>
    <el-form :model="form" status-icon :rules="rules" ref="rules">
      <div style="position: absolute">
        <input type="text" style="height: 0; width: 0; border: 0; padding: 0" />
        <input type="password" style="height: 0; width: 0; border: 0; padding: 0" />
      </div>
      <el-form-item prop="username">
        <el-input class="login-input" type="text" v-model.trim="form.username" placeholder="账号/手机号"> </el-input>
      </el-form-item>
      <el-form-item prop="oldPassword">
        <el-input class="login-input" type="password" v-model.trim="form.oldPassword" show-password placeholder="请输入原密码"> </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input class="login-input" type="password" v-model.trim="form.password" maxlength="20" show-password placeholder="请输入新密码"> </el-input>
      </el-form-item>
      <el-form-item prop="rePassword">
        <el-input class="login-input" type="password" v-model.trim="form.rePassword" show-password maxlength="20" placeholder="请确认新密码"> </el-input>
      </el-form-item>
      <div class="login-flex">
        <el-button class="register-button" type="primary" @click="submitForm">确定</el-button>
        <span @click="changedComponent">{{ buttonTitle }}</span>
      </div>
    </el-form>
  </div>
</template>

<script>
import { editPassword } from '@/service/login.js';

export default {
  name: 'ForgetPassword',
  props: ['username'],
  watch: {
    username: {
      handler(nVal) {
        this.form.username = nVal;
      },
      immediate: true
    }
  },
  data() {
    return {
      errorMessage: '',
      errorVisible: false,
      loading: false,
      interval: null,
      timer: 0,
      buttonTitle: '想起密码，去登录',
      form: {
        username: '',
        oldPassword: '',
        password: '',
        rePassword: '',
        bizType: 'updatePassword'
      },
      rules: {
        username: [{ required: true, message: '账号/手机号必填！', trigger: 'blur' }],
        oldPassword: [{ required: true, message: '原密码必填！', trigger: 'blur' }],
        password: [
          { required: true, min: 10, max: 20, message: '请输入10-20位密码（数字、大小写字母、特殊字符组合）!', trigger: 'blur' },
          { pattern: "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&+=,./?;'])(?=\\S+$).{10,20}$", message: '至少要包含数字、大小写字母、特殊字符（!@#$%^&+=）', trigger: 'blur' }
        ],
        rePassword: [
          { required: true, min: 10, max: 20, message: '请输入10-20位密码（数字、大小写字母、特殊字符组合）!', trigger: 'blur' },
          { pattern: "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&+=,./?;'])(?=\\S+$).{10,20}$", message: '至少要包含数字、大小写字母、特殊字符（!@#$%^&+=）', trigger: 'blur' },
          { validator: this.checkRePassword, trigger: 'blur' }
        ]
      }
    };
  },
  created() {},
  methods: {
    /* 校验确认密码 */
    checkRePassword(rule, value, callback) {
      if (this.form.password !== this.form.rePassword) {
        callback(new Error('两次输入新密码不一致！'));
      } else {
        callback();
      }
    },
    /* 触发校验，提交表单 */
    submitForm() {
      this.$refs.rules.validate((valid) => {
        if (!valid) return;
        this.handleEditPassword();
      });
    },
    /* 修改密码 */
    async handleEditPassword() {
      const { success, data, message } = await editPassword(this.form);
      if (!success) {
        this.toast(message, 'error');
        return;
      }
      this.$emit('changedLoginComponent', {
        status: 'register-success',
        register: false,
        message: data
      });
    },
    /* 切换组件到登录 */
    changedComponent() {
      this.$emit('changedLoginComponent', {
        status: 'index',
        register: false,
        message: ''
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/login.scss';

.login-input {
  width: 380px;
}

::v-deep .el-form-item__label,
::v-deep .el-form-item__content {
  height: inherit;
  line-height: inherit;
}

::v-deep .el-input,
::v-deep .el-input__inner {
  height: 40px;
}

.el-button {
  padding: 0 16px;
}
</style>
