<template>
    <div>
        <el-alert
                class="error-alert"
                v-show="errorVisible"
                title="注册信息错误"
                type="error"
                :closable="true"
                show-icon>
        </el-alert>
        <div class="title">注册</div>
        <el-form :model="form" status-icon :rules="rules" ref="rules">
            <div style="position: absolute">
                <input type="text" style="height: 0;width: 0;border:0;padding:0">
                <input type="password" style="height: 0;width: 0;border:0;padding:0">
            </div>
            <el-form-item prop="username">
                <el-input
                        class="login-input"
                        type="text"
                        v-model.trim="form.username"
                        placeholder="账户名"
                        maxlength="15">
                </el-input>
            </el-form-item>
            <el-form-item prop="phone">
                <el-input
                        class="login-input"
                        type="tel"
                        v-model.trim="form.phone"
                        placeholder="手机号">
                </el-input>
            </el-form-item>
            <el-form-item prop="code">
                <el-input
                        class="pin-input"
                        type="text"
                        v-model.trim="form.code"
                        maxlength="6"
                        placeholder="验证码">
                </el-input>
                <el-button class="pin-button" :loading="loading" type="default" @click="handleSendSms(true)">
                    {{ codeButton }}
                </el-button>
            </el-form-item>
            <el-form-item prop="source">
                <el-select v-model="form.source" placeholder="请选择来源系统" class="login-input">
                    <el-option v-for="item in systemSources"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="tenantName">
                <el-input
                        name="tenantName"
                        class="login-input"
                        type="text"
                        v-model.trim="form.tenantName"
                        autocomplete="off"
                        maxlength="128"
                        placeholder="单位名称">
                </el-input>
            </el-form-item>
            <el-form-item prop="password">
                <el-input
                        class="login-input"
                        type="password"
                        v-model.trim="form.password"
                        show-password
                        @blur="handleEmitCheckRepassword"
                        maxlength="15"
                        placeholder="6-15位密码，区分大小写">
                </el-input>
            </el-form-item>
            <el-form-item prop="rePassword">
                <el-input
                        class="login-input"
                        type="password"
                        v-model.trim="form.rePassword"
                        show-password
                        maxlength="15"
                        placeholder="确认密码">
                </el-input>
            </el-form-item>
            <div class="login-flex">
                <el-button class="register-button" type="primary" @click="submitForm()">
                    注册
                </el-button>
                <span @click="changedComponent">使用已有账号登录</span>
            </div>
        </el-form>
    </div>
</template>

<script>
    import RegexLib from "@/assets/js/regex-lib";
    import {checkUserNameHaven, checkPhoneHaven, sendSms, register} from "@/service/login.js"

    export default {
        name: "Register",
        data() {
            return {
                errorVisible: false,
                interval: null,
                loading: false,
                timer: 0,
                codeButton: "获取验证码",
                errorMessage: "",
                form: {
                    username: "",
                    phone: "",
                    code: "",
                    tenantName: "",
                    password: "",
                    rePassword: "",
                    bizType: "register",
                    source: "XTZS"
                },
                systemSources: [
                    {value: 'XTZS', label: '--'},
                    {value: 'FZBK', label: '菲住布渴'},
                    {value: 'SJXX', label: '石基信息'}
                ],
                rules: {
                    tenantName: [{required: true,message: '请输入单位名称',trigger: 'blur'}],
                    username: [
                        {required: true, min: 4, max: 20, message: "请输入4-20位账户名!", trigger: 'blur'},
                        {pattern: RegexLib.Username, message: '须是4~20位字母、数字、-_@.', trigger: 'blur'},
                        {validator: this.checkUserName, trigger: 'blur'}
                    ],
                    phone: [
                        {required: true, message: "请输入手机号!", trigger: 'blur'},
                        {pattern: RegexLib.Phone, message: "请输入正确的手机号!", trigger: 'blur'},
                        {validator: this.checkPhone, trigger: 'blur'}
                    ],
                    code: [
                        {required: true, message: "请输入验证码!", trigger: 'blur'},
                        {min: 1, max: 6, message: '长度在 1 到 6 个字符', trigger: 'blur'},
                        {pattern: RegexLib.SMSCheckCode, message: "验证码只能输入数字", trigger: 'blur'}
                    ],
                    password: [
                        {required: true, min: 4, max: 30, message: "请输入4-30位密码!", trigger: 'blur'},
                        {pattern: RegexLib.Password, message: '须是 4~30 位英文/数字/-@_!$#', trigger: 'blur'}
                    ],
                    rePassword: [
                        {required: true, min: 4, max: 30, message: "请输入4-30位密码!", trigger: 'blur'},
                        {pattern: RegexLib.Password, message: '须是 4~30 位英文/数字/-@_!$#', trigger: 'blur'},
                        {validator: this.checkRePassword, trigger: 'blur'}
                    ]
                }
            };
        },
        created() {
            if (localStorage.getItem("timer")) {
                this.timer = localStorage.getItem("timer");
                this.handleSendSms(false);
            }
            localStorage.clear();
        },
        methods: {
            /* 提交表单 */
            submitForm() {
                this.$refs.rules.validate(valid => {
                    if (valid) {
                        register(this.form).then(res => {
                            if (res.success) {
                                this.$emit("changedLoginComponent", {
                                    status: "register-success",
                                    register: true,
                                    message: this.form.username
                                });
                            } else {
                                this.errorMessage = res.msg;
                                this.errorVisible = !this.errorVisible;
                            }
                        })
                    } else {
                        return false
                    }
                })
            },
            /* 切换组件（回到登录） */
            changedComponent() {
                this.$emit("changedLoginComponent", {
                    status: "index",
                    register: false,
                    message: ""
                });
            },
            /* 短信验证码计时器 */
            timerDown() {
                if (this.timer < 1) {
                    clearInterval(this.interval)
                    this.loading = false;
                    this.codeButton = "获取验证码";
                    localStorage.removeItem("registerTimer");
                } else {
                    this.codeButton = this.timer - 1 + "秒";
                    localStorage.setItem("registerTimer", this.timer);
                    this.timer--;
                }
            },
            /* 发送短信验证码 */
            handleSendSms(param) {
                // 是否点击
                if (param) {
                    this.$refs.rules.validateField('phone', valid => {
                        // 校验手机
                        if (!valid) {
                            this.timer = 60;
                            localStorage.setItem("registerTimer", 60);
                            sendSms({
                                'phone': this.form.phone,
                                'bizType': this.form.bizType,
                                'expired': 60000
                            }).then(res => console.log(res));
                            this.loading = true;
                            this.codeButton = this.timer + "秒";
                            this.interval = setInterval(() => {
                                this.timerDown()
                            }, 1000);
                        } else {
                            return false;
                        }
                    });
                } else {
                    this.loading = true;
                    this.codeButton = this.timer + "秒";
                    this.interval = setInterval(() => {
                        this.timerDown();
                    }, 1000);
                }
            },
            /* 校验账户名 */
            async checkUserName(rule, value, callback) {
                const {data} = await checkUserNameHaven({"username": value});
                if (data) {
                    callback(new Error("账户名重复,请重新输入！"));
                }
                callback();
            },
            /* 校验手机号 */
            async checkPhone(rule, value, callback) {
                const {data} = await checkPhoneHaven({phone: value});
                if (data) {
                    callback(new Error("当前手机号已存在"));
                } else {
                    callback()
                }
            },
            /* 校验确认密码 */
            checkRePassword(rule, value, callback) {
                if (this.form.password != this.form.rePassword) {
                    callback(new Error("两次输入密码不一致！"));
                } else {
                    callback();
                }
            },
            /* 触发确认密码校验 */
            handleEmitCheckRepassword() {
                if (this.form.rePassword) {
                    this.$refs.rules.validateField("rePassword");
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "@/style/login.scss";

    .login-input {
        width: 380px;
    }

    ::v-deep .el-form-item__label, ::v-deep .el-form-item__content {
        height: inherit;
        line-height: inherit;
    }

    ::v-deep .el-input, ::v-deep .el-input__inner {
        height: 40px;
    }

    .el-button {
        padding: 0 16px;
    }

    ::v-deep .login-input .el-input {
        width: 100%;
    }
</style>
