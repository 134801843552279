import store from '@/store/index'
import { ReqEnumsList } from '@/service/enums'
import { ReqOrganizationGetSelectList } from '@/service/organization'

export const SystemInit = () => {
  return new Promise(function (resolve, reject) {
    Promise.all([ReqEnumsList(), ReqOrganizationGetSelectList()]).then((results) => {
      let elRes = results[0]
      if (elRes.success) {
        store.state.enums = elRes.data
      }

      let ogslRes = results[1]
      if (ogslRes.success) {
        store.commit('user/SET_ORGANIZATIONS', ogslRes.data)
      }
      resolve()
    }).catch((err) => {
      reject(err)
    })
  })
}