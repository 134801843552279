<template>
  <div>
    <el-alert class="error-alert" v-show="errorVisible" :title="errorMessage" type="error" :closable="true" show-icon> </el-alert>
    <div class="title">账号密码登录</div>
    <el-form :model="form" status-icon :rules="rules" ref="form" @keyup.enter.native="handleSignIn">
      <el-form-item prop="username">
        <el-input class="login-input" type="text" id="username" v-model.trim="form.username" maxlength="15" placeholder="账号/手机号">
          <img slot="prefix" src="@/assets/icon/usr.png" />
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input class="login-input" type="password" v-model.trim="form.password" maxlength="20" show-password placeholder="密码"> <img slot="prefix" src="@/assets/icon/lock.png" /></el-input>
      </el-form-item>
      <div class="login-flex">
        <el-checkbox v-model="form.checkbox" @change="checkBoxChanged">自动登录</el-checkbox>
        <div>
          <span class="update-password" @click="handleUpdate">修改密码?</span>
          <span class="forgot-password" @click="handleForgot">忘记密码?</span>
        </div>
      </div>
      <el-form-item>
        <el-button class="login-button" type="primary" :loading="loginLoading" :disabled="loginLoading" @click="handleSignIn">登录 </el-button>
      </el-form-item>
      <div class="login-flex" v-if="loginType == 'pdt'">
        <div class="wechat-login">
          <span>其他登录方式</span>
          <img src="@/assets/icon/wxLogin.png" alt="微信" @click="handelWeiXinLogin" />
        </div>
        <div class="forgot-password" @click="handleSignUp">注册账号</div>
      </div>
    </el-form>
  </div>
</template>

<script>
import RegexLib from '@/assets/js/regex-lib';
import { ReqSignIn, weiXinLogin } from '@/service/login';

export default {
  name: 'LoginIndex',
  props: ['loginType'],
  data() {
    return {
      centerColData: '',
      colData: '',
      hotelColData: '',
      merchandiseInfoData: '',
      customerInfoData: '',
      recognizeFailureLineSet: '',
      originalOrderLineSet: '',
      middleKindLineSet: '',
      otherInvoiceLineSet: '',
      setOrgId:'',
      errorVisible: false,
      errorMessage: '',
      name: '多啦财税',
      loginLoading: false,
      form: {
        checkbox: false,
        username: '',
        password: ''
      },
      rules: {
        username: [
          { required: true, message: '请输入账户名/手机号', trigger: 'blur' },
          { min: 4, max: 20, message: '请输入在 4 到 20 位账号', trigger: 'blur' },
          { pattern: RegexLib.Username, message: '须是10~20位字母、数字、-_@.', trigger: 'blur' }
        ],
        password: [
          { required: true, min: 4, max: 30, message: '请输入10-20位密码!', trigger: 'blur' },
          { pattern: RegexLib.Password, message: '须是 10~20 位英文/数字/-@_!$#', trigger: 'blur' }
        ]
      }
    };
  },
  mounted() {
    if (localStorage.getItem('checkbox')) {
      this.form.checkbox = true;
      this.form.username = localStorage.getItem('username');
      this.form.password = this.passwordAtoB(localStorage.getItem('password'));
      setTimeout(() => this.handleSignIn(), 1000);
    }
  },
  methods: {
    handleSignIn() {
      this.checkBoxChanged();
      this.errorVisible = false;
      this.$refs['form'].validate((valid) => {
        if (!valid) return;
        localStorage.removeItem('token');
        this.loginLoading = true;
        ReqSignIn(this.form)
          .then((res) => {
            this.loginLoading = false;
            if (!res.success && res.code == -1) {
              this.errorVisible = true;
              this.errorMessage = res.message;
              return;
            }
            this.$emit('loginSuccess', res.data);
          })
          .catch((err) => {
            console.error('登录出错！', err);
            this.loginLoading = false;
          });
      });
    },
    handelWeiXinLogin() {
      weiXinLogin().then((res) => {
        if (res.success) {
          let link = document.createElement('a');
          link.style.display = 'none';
          link.setAttribute('href', res.data);
          document.body.appendChild(link);
          link.click();
        }
      });
    },
    checkBoxChanged() {
      if (this.form.checkbox == true) {
        localStorage.setItem('checkbox', true);
        localStorage.setItem('username', this.form.username);
        localStorage.setItem('password', this.passwordBtoA(this.form.password));
      } else {
        this.colData = JSON.parse(localStorage.getItem('lineSet'));
        this.centerColData = JSON.parse(localStorage.getItem('centerLineSet'));
        this.hotelColData = JSON.parse(localStorage.getItem('hotelLineSet'));
        this.merchandiseInfoData = JSON.parse(localStorage.getItem('merchandiseInfo'));
        this.customerInfoData = JSON.parse(localStorage.getItem('customerInfo'));
        this.recognizeFailureLineSet = JSON.parse(localStorage.getItem('recognizeFailureLineSet'));
        this.originalOrderLineSet = JSON.parse(localStorage.getItem('originalOrderLineSet'));
        this.middleKindLineSet = JSON.parse(localStorage.getItem('middleKindLineSet'));
        this.emailInfoData = JSON.parse(localStorage.getItem('emailInfo'));
        this.eventInfoData = JSON.parse(localStorage.getItem('eventInfo'));
        this.otherInvoiceLineSet = JSON.parse(localStorage.getItem('otherInvoiceLineSet'));
        this.setOrgId = JSON.parse(localStorage.getItem('setOrgId'));

        localStorage.clear();
        localStorage.setItem('lineSet', JSON.stringify(this.colData));
        localStorage.setItem('centerLineSet', JSON.stringify(this.centerColData));
        localStorage.setItem('hotelLineSet', JSON.stringify(this.hotelColData));
        localStorage.setItem('merchandiseInfo', JSON.stringify(this.merchandiseInfoData));
        localStorage.setItem('customerInfo', JSON.stringify(this.customerInfoData));
        localStorage.setItem('recognizeFailureLineSet', JSON.stringify(this.recognizeFailureLineSet));
        localStorage.setItem('middleKindLineSet', JSON.stringify(this.middleKindLineSet));
        localStorage.setItem('emailInfo', JSON.stringify(this.emailInfoData));
        localStorage.setItem('eventInfo', JSON.stringify(this.eventInfoData));

        localStorage.setItem('originalOrderLineSet', JSON.stringify(this.originalOrderLineSet));
        localStorage.setItem('otherInvoiceLineSet', JSON.stringify(this.otherInvoiceLineSet));
        localStorage.setItem('setOrgId', JSON.stringify(this.setOrgId));
      }
    },
    handleUpdate() {
      this.$emit('changedLoginComponent', {
        status: 'update-password',
        register: false,
        username: this.form.username
      });
    },

    handleSignUp() {
      this.$emit('changedLoginComponent', {
        status: 'register',
        register: false,
        message: ''
      });
    },
    handleForgot() {
      this.$emit('changedLoginComponent', {
        status: 'forgot-password',
        register: false,
        message: ''
      });
    },
    passwordBtoA(value) {
      return btoa('yo82mvyr' + value + escape(this.name));
    },
    passwordAtoB(value) {
      let unescape1 = unescape(atob(value).substring(8));
      return unescape1.substring(-1, unescape1.length - this.name.length);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/login.scss';
.update-password {
  padding-right: 10px;
}

.title {
  padding-bottom: 8px;
  margin-bottom: 26px;
  border-bottom: 2px solid #3d94ff;
  color: #3d94ff;
  font-size: 16px;
  font-weight: 400;
  width: 96px;
}

::v-deep .el-form-item__label,
::v-deep .el-form-item__content {
  height: inherit;
  line-height: inherit;
}

::v-deep .el-input {
  height: 40px;
}

::v-deep .el-input__inner {
  height: 40px;
}

::v-deep .el-form-item__error {
  top: 40px;
}
</style>
